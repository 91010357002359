<template lang='haml'>
.segments-renderer
  %template(v-for="(segment,i) in internalSegments")
    %template(v-if="displaySegment(segment)")
      %component(:is="componentName(segment)" :segment="segment" v-model="values[segment.ident]" :action="action" :key="i")
</template>

<script>
import ChoiceSegment from './segments/ChoiceSegment'
import ReplySegment from './segments/ReplySegment'
import AttachmentSegment from './segments/AttachmentSegment'
import TextSegment from './segments/TextSegment'
import CheckboxSegment from './segments/CheckboxSegment'
import FormSegment from './segments/FormSegment'
import DoctorsFormSegment from './segments/DoctorsFormSegment'

export default {
  components: {
    ChoiceSegment,
    ReplySegment,
    TextSegment,
    AttachmentSegment,
    CheckboxSegment,
    FormSegment,
    DoctorsFormSegment
  },
  props: ['segments', 'action', 'isDoctor', 'additionalQuestion', 'contextMessage', 'templateCode',
    'customFormSections'],
  data() { return { values: {} } },
  methods: {
    // Handles the "show_when" value
    displaySegment(segment) {
      if (segment.class_name == 'SectionSegment') return false
      if (segment.doctor_only && this.isDoctor !== true) return false
      if (!segment.show_when) return true
      const conditions = segment.show_when.split('&&').map((c) => c.trim())
      return conditions.every((condition) => {
        const parts = condition.split('=')
        if (parts.length != 2) return true
        return this.values[parts[0]] == parts[1]
      })
    },
    componentName(segment) {
      if (segment.class_name == 'ActionSegment') return 'ChoiceSegment'
      return segment.class_name || 'TextSegment'
    }
  },
  computed: {
    internalSegments() {
      let result = this.segments

      // Mimick same logic than Form.add_context_and_additional_questions
      // TODO : remove this frontend logic and use API to get correct rendered value
      if (this.contextMessage) {
        result = [{ value: `<h3>${this.contextMessage}</h3>` }, ...result]
      }
      if (this.segments.some((s) => s.ident == 'custom_form_section_start')) {
        const section = { start: [], form: [], end: [] }
        let currentKey = 'start'
        result.forEach((segment) => {
          if (segment.ident == 'custom_form_section_start') currentKey = 'form'
          else if (segment.ident == 'custom_form_section_end') currentKey = 'end'
          else section[currentKey].push(segment)
        })
        if (section.form.length > 0) {
          result = section.start
          const sections = this.customFormSections || 'Health Issue 1'
          sections.split('\n').forEach((question) => {
            result.push({ value: `<h2>${question}</h2>` })
            result = result.concat(JSON.parse(JSON.stringify(section.form)))
          })
          result = result.concat(section.end)
        }
      }
      if (this.additionalQuestion) {
        result.push({ value: `<h2>${this.$t('comm.transmissions.form.additional_question')}</h2>` })
        this.additionalQuestion.split('\n').forEach((question, index) => {
          result.push({ value: `<p>${question}</p>` })
          result.push({
            class_name: 'ReplySegment',
            ident: `additional_question_${index}`,
            options: { required: true, rows: 3 }
          })
        })
      }

      this.segments.forEach((segment) => {
        if (segment.ident) this.$set(this.values, segment.ident, segment.value)
      })

      return result
    }
  }
}

</script>

<style lang='scss'>
  // Customize display inside myCourses
  .segments-renderer {
    p {
      margin: 1em 0; // restore default p margins (was ovewritten by bootstrap)
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 1em;
    }

    h2 {
      font-size: 1.9rem;
      color: var(--bs-secondary);
    }
    h3 {
      font-size: 1.4rem;
    }
    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
  // Customize display in CALM
  #calm-body .segments-renderer {
    --bs-secondary: var(--bs-primary);
  }
</style>
